<template>
  <div class="join">
    <div class="banner">
      <div class="content">
        <div class="title" data-aos="fade-up">
          <div>{{$t('join.bannerText')}}</div>
          <div>{{$t('join.bannerText2')}}</div>
        </div>
      </div>
    </div>
    <div class="bannerMb">
      <div class="content">
        <div class="title" data-aos="fade-up">
          <div>{{$t('join.bannerText')}}</div>
          <div>{{$t('join.bannerText2')}}</div>
        </div>
      </div>
    </div>
    <div class="mainCotnentBox">
      <img class="w1" src="@/assets/w1.png" alt="">
      <img class="w2" src="@/assets/w2.png" alt="">
      <img class="w3" src="@/assets/w3.png" alt="">
      <div class="mainCotnent" v-if="joinusdata.place">
        <!-- <div class="titleTop" data-aos="fade-up">{{$t('join.didian')}}</div> -->
        <div class="address" :class="addressIdx == 0?'s1':addressIdx == 1?'s2': addressIdx == 2?'s3':'s4'" >
          <div class="item" :style="{width: addressIdx == index?'100%':'25%'}" v-for="(item,index) in joinusdata.place" :key="index" @mousemove="onAddress(index)" >
            <div class="info">
              <div class="title" :style="{textAlign: addressIdx == index?'left':'center'}">{{item.name}}</div>
              <div class="tt" v-if="addressIdx == index">
                <img class="icon_add" src="@/assets/icon_add.png" alt="">
                <div>
                  <div v-html="item.address"></div>
                  <div class="s" v-html="item.address1"></div>
                </div>
              </div>
              <div class="tt tt2" :style="{opacity: addressIdx == index?'1':'0'}">
                <img class="ico_mail" src="@/assets/ico_mail.png" alt="">
                <div>{{item.email}}</div>
              </div>
            </div> 
          </div> 
        </div>
        <div v-if="$i18n.locale == 'CN'"> 
          <div class="titleTop" data-aos="fade-up">{{$t('join.zhiwei')}}</div> 
          <div class="zhiweiNav" v-if="joinusdata.jobsort">
            <div class="item" :class="zhiweiNav == index?'active':''" @click="onzhiweiNav(item,index)" v-for="(item,index) in joinusdata.jobsort" :key="index">{{item.title}}</div>
          </div>
          <div class="zhiwei" v-if="joinusdata.work">
            <el-collapse>
              <el-collapse-item class="list" v-for="(item,index) in joinusdata.work" :key="index" @click="onZhiwei(index)">
                <template slot="title">
                  <div class="itemBox">
                    <img class="icon_job" src="@/assets/icon_job.png" alt="">
                    <div class="text">{{item.title}}</div>
                    <img class="icon_add" src="@/assets/icon_add(1).png" alt="">
                    <div class="addM">
                      <span v-for="(itemC,indexC) in item.reginname" :key="indexC">{{itemC}}{{indexC+1 != item.reginname.length?'、':''}}</span>
                    </div>
                  </div>
                </template>
                <div class="mainText" >
                  <div v-html="item.content"></div> 
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="titleTop" data-aos="fade-up">{{$t('join.zhiwei2')}}</div>
          <div class="zhiwei" v-if="joinusdata.campus">
            <el-collapse>
              <el-collapse-item class="list" v-for="(item,index) in joinusdata.campus" :key="index" @click="onZhiwei(index)">
                <template slot="title">
                  <div class="itemBox">
                    <img class="icon_job" src="@/assets/icon_job.png" alt="">
                    <div class="text">{{item.title}}</div>
                    <img class="icon_add" src="@/assets/icon_add(1).png" alt="">
                    <div class="addM">
                      <span v-for="(itemC,indexC) in item.reginname" :key="indexC">{{itemC}}{{indexC+1 != item.reginname.length?'、':''}}</span>
                    </div>
                  </div>
                </template>
                <div class="mainText" >
                  <div v-html="item.content"></div> 
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="mainCotnentBoxMb">
      
      <div class="mainCotnent">
        <!-- <div class="titleTop" data-aos="fade-up">{{$t('join.didian')}}</div> -->
        <div class="address" :class="addressIdx == 0?'s1':addressIdx == 1?'s2': addressIdx == 2?'s3':'s4'" >
          <div class="item" :style="{height: addressIdx == index?'calc(100% - 60%)':'20%'}" v-for="(item,index) in joinusdata.place" :key="index" @mousemove="onAddress(index)">
            <div class="info">
              <div class="title" :style="{textAlign: addressIdx == index?'left':'center',marginBottom:addressIdx == index?'4vw':'0'}">{{item.name}}</div>
              <div class="tt" v-if="addressIdx == index">
                <img class="icon_add" src="@/assets/icon_add.png" alt="">
                <div>
                  <div v-html="item.address"></div>
                  <div class="s" v-html="item.address1"></div>
                </div>
              </div>
              <div class="tt tt2" v-if="addressIdx == index">
                <img class="ico_mail" src="@/assets/ico_mail.png" alt="">
                <div>{{item.email}}</div>
              </div>
            </div> 
          </div> 
        </div>
        <div v-if="$i18n.locale == 'CN'"> 
          <div class="titleTop" data-aos="fade-up">{{$t('join.zhiwei')}}</div>

          <div class="zhiweiNav" v-if="joinusdata.jobsort">
            <div class="item" :class="zhiweiNav == index?'active':''" @click="onzhiweiNav(item,index)" v-for="(item,index) in joinusdata.jobsort" :key="index">{{item.title}}</div>
          </div>
          <div class="zhiwei">
            <el-collapse>
              <el-collapse-item class="list" v-for="(item,index) in joinusdata.work" :key="index" @click="onZhiwei(index)">
                <template slot="title">
                  <div class="itemBox">
                    <img class="icon_job" src="@/assets/icon_job.png" alt="">
                    <div class="text">{{item.title}}</div>
                  </div>
                  <div class="itemBox">
                    <img class="icon_add" src="@/assets/icon_add(1).png" alt="">
                    <div class="addM">
                      <span v-for="(itemC,indexC) in item.reginname" :key="indexC">{{itemC}}{{indexC+1 != item.reginname.length?'、':''}}</span>
                    </div>
                  </div>
                </template>
                <div class="mainText" >
                  <div v-html="item.content"></div> 
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="titleTop" data-aos="fade-up">{{$t('join.zhiwei2')}}</div>
          <div class="zhiwei">
            <el-collapse>
              <el-collapse-item class="list" v-for="(item,index) in joinusdata.campus" :key="index" @click="onZhiwei(index)">
                <template slot="title">
                  <div class="itemBox">
                    <img class="icon_job" src="@/assets/icon_job.png" alt="">
                    <div class="text">{{item.title}}</div>
                  </div>
                  <div class="itemBox">
                    <img class="icon_add" src="@/assets/icon_add(1).png" alt="">
                    <div class="addM">
                      <span v-for="(itemC,indexC) in item.reginname" :key="indexC">{{itemC}}{{indexC+1 != item.reginname.length?'、':''}}</span>
                    </div>
                  </div>
                </template>
                <div class="mainText" >
                  <div v-html="item.content"></div> 
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {baseHostUrl,getjoinusdata} from '@/utils/index.js'
import WXConfig from '@/utils/wx.jsapi.js'; 
export default {
  name: 'join',
  components: {

  },
  data () {
    return {
      addressIdx: 0,
      zhiweiInx: null,
      joinusdata: {},
      workData: [],
      img1: require('../assets/s1.jpg'),
      img2: require('../assets/s2.jpg'),
      img3: require('../assets/s3.jpg'),
      zhiweiNav: 0,
      language: localStorage.getItem('language').toLowerCase()
    }
  },
  computed: {
  },
  mounted() {
		document.title = '云脉芯联招聘岗位';
    this.getjoinusdata()
		 this.getShare(); 
  },
  methods: {
    onzhiweiNav(item,index) {
      this.zhiweiNav = index
      this.getjoinusdata(item.id)
    },
    async getjoinusdata(id) {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        sid: id | ''
      }
      let res = await getjoinusdata(data)
      let newBoj = {
        select: false
      }
      for(let i in res.data.work) {
        Object.assign(res.data.work[i],newBoj)
      }
      this.joinusdata = res.data
    },
    onAddress(index) {
      this.addressIdx = index
    },
    onZhiwei(index) {
      this.joinusdata.work[index].select = !this.joinusdata.work[index].select
      
    },
    getShare(){
      let _this = this     
      let shareData = { 
        shareurl:  location.href, 
        title: '云脉芯联招聘岗位',
        desc: '',
        imgUrl: 'https://www.yunsilicon.com/video/logo.jpg' 
      }; 
      WXConfig.wxShowMenu(shareData);
    }
  }
}
</script>
<style>
.mainText p{
  margin: 0 !important;
  text-indent: unset !important;
}
</style>
<style lang="scss">
 .list{
   .el-icon-arrow-right{
    color: #127CC0;
    font-weight: 700;
    font-size: 1.2vw;
  }
  .el-collapse-item__header{
    border-bottom: none;
    line-height: auto;
  }
  .el-collapse-item__wrap{
    border: none;
  }
 }
 .el-collapse{
   border: none;
 }
 @media screen and (max-width:750px){
  .el-collapse-item__header{
    display: block;
    position: relative;
    height: auto !important;
    line-height: auto !important;
  }
  .el-collapse-item__arrow{
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 0;
    transform: translate(0,-50%);
  }
  .el-collapse-item__arrow.is-active{
    transform: translate(0,-50%) rotate(90deg);
  }
 
  .itemBox{
    border-bottom: none !important;
  }
  .el-icon-arrow-right{
    color: #127CC0;
    font-weight: 700;
    font-size: 4vw !important;
  }
 }
</style>
<style lang="scss" scoped>

.join{
  background: #F6F7F9;
}
.banner{
  background: url('../assets/join.png') no-repeat;
  height: 31vw;
  background-size: cover;
  display: flex;
  align-items: center;
  .content{
    width: 63vw;
    margin: auto;
    display: flex;
    align-items: center;
    .title{
      font-size: 1.8vw;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 1.6;
    }
  }
}
.bannerMb{
  display: none;
}
.mainCotnent{
  width: 63vw;
  margin: auto;
  padding: 4vw 0;
  .titleTop{
    font-size: 1.6vw;
    font-weight: 700;
    text-align: center;
    color: #127CC0;
  }
  .zhiweiNav{
    display: flex;
    align-items: center;
    height: 3.1vw;
    background: #EBEEF3;
    padding: 0 10px;
    margin: 4vw 0 0 0;
    .item{
      font-size: 0.9vw;
      color: #666666;
      flex: 0 0 20%; 
      text-align: center;
      padding: 8px 0;
      cursor: pointer;
    }
    .active{
      background: #fff;
      color: #127CC0;
      font-weight: bold;
    }
  }
  .address{
    background: url('../assets/s1.jpg') no-repeat;
    background-size: cover;
    height: 27.5vw;
    margin: 4vw 0 4vw 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    .item{
      width: 25%;
      height: 27.5vw;
      border-right: solid 1px rgba($color: #fff, $alpha: 0.5);
      display: flex;
      align-items: center;
      transition: all 0.3s ease-out;
      cursor: pointer;
      &:last-child{
        border-right: none;
      }
      .info{
        padding: 0 2vw;
        color: #fff;
        .title{
          font-size: 1.2vw;
          
          font-weight: 700;
          margin-bottom: 1vw;
        }
        .tt{
          font-size: 0.8vw;
          transition: all 0.3s ease-out;
 
          display: flex;
          .s{
            margin-top: .3vw;
          }
          .icon_add{
            height: 14px;
            height: 16px;
            margin-right: 0.6vw;
            margin-top: 0.15vw;
          }
          .ico_mail{
            height: 16px;
            height: 12px;
            margin-right: 0.6vw;
          }
        }
        .tt2{
          align-items: center;
          margin-top: 1vw;
        }
      }
      .infoM{
        font-size: 1.6vw;
        text-align: center;
        font-weight: 700;
        color: #fff;
        flex: 1;
      }
    }
  }
  .s1{
     background: url('../assets/s1.jpg') no-repeat;
     background-size: 100% 100%;
     background-position: 50% 50%;
  }
  .s2{
     background: url('../assets/s2.jpg') no-repeat;
     background-size: 100% 100%;
     background-position: 50% 50%;
  }
  .s3{
     background: url('../assets/s3.jpg') no-repeat;
     background-size: 100% 100%;
     background-position: 50% 50%;
  }
  .s4{
     background: url('../assets/s4.jpg') no-repeat;
     background-size: 100% 100%;
     background-position: 50% 50%;
  }
  .zhiwei{
    padding: 24px 0 3vw 0;
    .list{
      padding: 0.8vw 2vw;
      background: #fff;
      margin-bottom:  2vw;
      .itemBox{
        flex: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        .icon_job{
          width: 19px;
          height: 19px;
        }
        .text{
          font-size: 1vw;
          font-weight: 700;
          color: #333;
          margin-left: 1vw;
        }
        .icon_add{
          margin-left: auto;
          width: 14px;
          height: 16px;
        }
        .addM{
          font-size: 0.75vw;
          color: #666666;
          margin: 0 1vw 0 1vw;
        }
        .icon_xiala{
          width: 14px;
          height: 9px;
          transition: all 0.3s ease-out;
        }
        .zhuan{
          transform: rotate(-90deg);
          transition: all 0.3s ease-out;
        }
        
      }
      
      .mainText{
        transition: all 0.3s ease-out;
        overflow: hidden;
        border-top: 1px solid #eee;
        .mt{
          font-size: 1vw;
          font-weight: 700;
          color: #333;
          padding: 2vw 0 0 0;
        }
        .juti{
          font-size: 0.8vw;
          color: #666666;
          margin-top: 1vw;
          line-height: 2;
          padding: 0 0 2vw 0;
        }
      }
    }
  }
}
.mainCotnentBox{
  position: relative;
  .w1{
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
    z-index: 0;
  }
  .w2{
    position: absolute;
    top: 30%;
    right: 0;
    width: 20vw;
  }
  .w3{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20vw;
    z-index: 0;
  }
}
.mainCotnentBoxMb{
  display: none;
}
@media screen and (max-width:750px){
  .banner{
    display: none;
  }
  .bannerMb{
    background: url('../assets/joinmb.png') no-repeat;
    height: 106vw;
    background-size: cover;
    display: flex;
    align-items: center;
    .content{
      margin: auto;
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 8vw;
      .title{
        font-size: 6.6vw;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 1.6;
      }
    }
  }
  .mainCotnentBox{
    display: none;
  }
  .mainCotnentBoxMb{
    display: block;
    position: relative;
    .mainCotnent{
      width: auto;
      margin: auto;
      padding: 5vw;
      .titleTop{
        font-size: 6vw;
        font-weight: 700;
        text-align: center;
        color: #127CC0;
        margin-top: 6vw;
      }
      .zhiweiNav{
        display: flex;
        align-items: center;
        height: 14vw;
        background: #EBEEF3;
        padding: 0 10px;
        margin: 10vw 0 0 0;
        .item{
          font-size: 3vw;
          color: #666666;
          flex: 0 0 25%; 
          text-align: center;
          padding: 3vw 0;
          cursor: pointer;
        }
        .active{
          background: #fff;
          color: #127CC0;
          font-weight: bold;
        }
      }
      .address{
        background: url('../assets/s1.jpg') no-repeat;
        background-size: cover;
        height: 135vw;
        margin: 4vw 0 8vw 0;
        position: relative;
        z-index: 1;
        display: block;
        .item{
          width: auto;
 
          border-bottom: solid 1px rgba($color: #fff, $alpha: 0.5);
          display: flex;
          align-items: center;
          transition: all 0.3s ease-out;
          cursor: pointer;
          &:last-child{
            border-right: none;
          }
          .info{
            padding: 0 7vw;
            color: #fff;
            width: 100%;
            .title{
              font-size: 5vw;
              font-weight: 700;
              margin-bottom: 4vw;
            }
            .tt{
              font-size: 3.5vw;
              margin-top: 0.8vw;
              transition: all 0.3s ease-out;

              display: flex;
       
              margin-bottom: 20px;
              .icon_add{
                height: 14px;
                height: 16px;
                margin-right: 3vw;
                margin-top: 1px;
              }
              .ico_mail{
                height: 16px;
                height: 12px;
                margin-right: 3vw;
              }
            }
            .tt2{
              align-items: center;
            }
          }
          .infoM{
            font-size: 4vw;
            text-align: center;
            font-weight: 700;
            color: #fff;
            flex: 1;
          }
        }
      }
      .s1{
        background: url('../assets/sm1.jpg') no-repeat;
        background-size: cover;
      }
      .s2{
        background: url('../assets/sm2.jpg') no-repeat;
        background-size: cover;
      }
      .s3{
        background: url('../assets/sm3.jpg') no-repeat;
        background-size: cover;
      }
      .s4{
        background: url('../assets/sm4.jpg') no-repeat;
        background-size: cover;
      }
      .zhiwei{
      
        .list{
          padding: 3vw 4vw;
          background: #fff;
          margin-bottom:  4vw;
          .itemBox{
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: solid 1px #EEEEEE;
            .icon_job{
              width: 19px;
              height: 19px;
            }
            .text{
              font-size: 4vw;
              font-weight: 700;
              color: #333;
              margin-left: 3vw;
            }
            .icon_add{
              margin-left: 0;
              width: 14px;
              height: 16px;
              margin-bottom: 3vw;
            }
            .addM{
              font-size: 3vw;
              color: #666666;
              margin: 0 2vw 0 1vw;
              line-height: 1;
              margin-bottom: 3vw;
            }
            .icon_xiala{
              width: 14px;
              height: 9px;
              transition: all 0.3s ease-out;
            }
            .zhuan{
              transform: rotate(-90deg);
              transition: all 0.3s ease-out;
            }
          }
          .mainText{
            transition: all 0.3s ease-out;
            overflow: hidden;
            .mt{
              font-size: 4vw;
              font-weight: 700;
              color: #333;
              padding: 2vw 0 0 0;
            }
            .juti{
              font-size: 3.5vw;
              color: #666666;
              margin-top: 2vw;
              line-height: 2;
              padding: 0 0 2vw 0;
            }
          }
        }
      }
    }
    .w1{
      position: absolute;
      top: 0;
      left: 0;
      width: 60vw;
      z-index: 0;
    }
    .w2{
      position: absolute;
      top: 30%;
      right: 0;
      width: 60vw;
    }
    .w3{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60vw;
      z-index: 0;
    }
  }
}
</style>