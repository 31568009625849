
import {WXshares} from '@/utils/index.js'
export default {
    async wxShowMenu(shareData) { 
        var wxData = shareData; //分享配置信息    
        let res = await WXshares(wxData.shareurl)    
        // console.log(res)
        var getMsg = res; 
        // console.log(wx,jWeixin)
        wx.config({
            debug: false, //生产环境需要关闭debug模式
            appId: getMsg.appId, //appId通过微信服务号后台查看
            timestamp: getMsg.timestamp, //生成签名的时间戳
            nonceStr: getMsg.nonceStr, //生成签名的随机字符串
            signature: getMsg.signature, //签名
            jsApiList: [ //需要调用的JS接口列表
                'onMenuShareTimeline', //分享给好友
                'onMenuShareAppMessage', //分享到朋友圈
            ]
        }); 
        wx.ready(function() { 
            //分享到朋友圈
            wx.onMenuShareTimeline({
                title: wxData.title, // 分享标题
                desc: wxData.desc, //分享描述
                link: wxData.shareurl, // 分享链接
                imgUrl: wxData.imgUrl, // 分享图标
                success: function(res) {
                    // 用户确认分享后执行的回调函数 
                    console.log("分享朋友圈成功返回的信息为:", res);
                },
                cancel: function(res) {
                    // 用户取消分享后执行的回调函数
                    console.log("取消分享朋友圈返回的信息为:", res);
                }
            });


            //分享给朋友
            wx.onMenuShareAppMessage({
                title: wxData.title, // 分享标题
                desc: wxData.desc, //分享描述
                link: wxData.shareurl, // 分享链接
                imgUrl: wxData.imgUrl, // 分享图标 
                success: function(res) {
                    // 用户确认分享后执行的回调函数 
                    console.log("分享朋友圈成功返回的信息为:", res);
                },
                cancel: function(res) {
                    // 用户取消分享后执行的回调函数
                    console.log("取消分享给朋友返回的信息为:", res);
                }
            }); 
        })   
	}
}